export default {
  SET_STATE: 'recordings/SET_STATE',

  INVOKE_LIST_RECORDINGS: 'recordings/INVOKE_LIST_RECORDINGS',
  INVOKE_GET_RECORDINGS: 'recordings/INVOKE_GET_RECORDINGS',

  INVOKE_CREATE_CALL: 'recordings/INVOKE_CREATE_CALL',

  SET_ADD_ALL_RECORDING_LIST: 'recordings/SET_ADD_ALL_RECORDING_LIST',
};
