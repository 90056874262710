export const i = 1;
export const routes = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  SIGNUP_WITH_INVITE: '/signup/invite/:code',
  SIGNUP_CREATE: '/signup/create',
  ORGANIZATION_EXISTS: '/signup/org-exists',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_EMAIL: '/verify-email',
  VERIFY_YOUR_EMAIL: '/verify-your-email',
  VERIFY_LOGIN_EMAIL: '/login-verify',
  SIGNOUT: '/logout',
  SEARCH: '/search',

  // JOBS_MAIN: `/jobs`,
  DASHBOARD: '/dashboard',
  JOB: (jobId) => `/jobs/${jobId}`,

  JOB_STAGES: (jobId) => `/jobs/${jobId}/stages`,
  JOB_STAGE: (jobId, stageId) => `/jobs/${jobId}/stages/${stageId}`,
  JOB_STAGE_APPLICANTS: (jobId, stageId) =>
    `/jobs/${jobId}/stages/${stageId}/applicants`,
  JOB_APPLICANT: (jobId, stageId, applicantId) =>
    `/jobs/${jobId}/stages/${stageId}/applicants/${applicantId}`,
  JOB_APPLICANT_OVERVIEW: (jobId, stageId, applicantId) =>
    `/jobs/${jobId}/stages/${stageId}/applicants/${applicantId}/overview`,
  JOB_APPLICANT_RESUME: (jobId, stageId, applicantId) =>
    `/jobs/${jobId}/stages/${stageId}/applicants/${applicantId}/resume`,
  JOB_APPLICANT_MESSAGES: (jobId, stageId, applicantId) =>
    `/jobs/${jobId}/stages/${stageId}/applicants/${applicantId}/messages`,
  JOB_APPLICANT_FILES: (jobId, stageId, applicantId) =>
    `/jobs/${jobId}/stages/${stageId}/applicants/${applicantId}/files`,
  JOB_APPLICANT_ADDITIONAL_FORM: (jobId, stageId, applicantId) =>
    `/jobs/${jobId}/stages/${stageId}/applicants/${applicantId}/form-entries`,
  JOB_APPLICANT_NOTES: (jobId, stageId, applicantId) =>
    `/jobs/${jobId}/stages/${stageId}/applicants/${applicantId}/notes`,
  JOB_APPLICANT_SCHEDULE_INTERVIEW: (jobId, stageId, applicantId) =>
    `/jobs/${jobId}/stages/${stageId}/applicants/${applicantId}/interview`,
  JOB_APPLICANT_RECORDINGS: (jobId, stageId, applicantId) =>
    `/jobs/${jobId}/stages/${stageId}/applicants/${applicantId}/recordings`,
  JOB_SETUP: (jobId) => `/jobs/${jobId}/setup`,
  JOB_SETUP_DETAILS: (jobId) => `/jobs/${jobId}/setup/details`,
  JOB_SETUP_ATTRIBUTES: (jobId) => `/jobs/${jobId}/setup/attributes`,
  JOB_SETUP_DESCRIPTION: (jobId) => `/jobs/${jobId}/setup/description`,
  JOB_SETUP_APPLICATION: (jobId) => `/jobs/${jobId}/setup/application`,
  JOB_SETUP_INTERVIEW: (jobId) => `/jobs/${jobId}/setup/interview`,
  JOB_SETUP_DOCUMENT: (jobId) => `/jobs/${jobId}/setup/document`,
  JOB_SETUP_WORKFLOW: (jobId) => `/jobs/${jobId}/setup/workflow`,
  JOB_SETUP_TEAM: (jobId) => `/jobs/${jobId}/setup/team`,
  JOB_DISTRIBUTION: (jobId) => `/jobs/${jobId}/distribution`,

  TALENT_COMMUNITY: '/talent-community',
  TALENT_COMMUNITY_APPLICANT: (id) => `/talent-community/${id}`,

  PORTAL_JOBS: (orgSlug) => `/portals/${orgSlug}`,
  PORTAL_JOB: (orgSlug, jobSlug) => `/portals/${orgSlug}/jobs/${jobSlug}`,
  PORTAL_APPLY_JOB: (orgSlug, jobSlug) =>
    `/portals/${orgSlug}/jobs/${jobSlug}/apply`,

  APPLICANT_REVIEW: (jobId, applicantId) =>
    `/applicant-reviews/jobs/${jobId}/applicants/${applicantId}`,

  ACCOUNT: '/account',
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_DOMAIN: '/account/domain',
  // ACCOUNT_PREFERENCES: '/account/preferences',
  ACCOUNT_CHANGE_PASSWORD: '/account/change-password',
  ACCOUNT_ORGANIZATION: '/account/organization',
  ACCOUNT_WORKFLOWS: '/account/workflows',
  ACCOUNT_WORKFLOW: (workflowId) => `/account/workflows/${workflowId}`,
  ACCOUNT_MEMBERS: '/account/members',
  ACCOUNT_MESSAGETEMPLATES: '/account/message-templates',
  ACCOUNT_MESSAGETEMPLATES_CREATE: '/account/message-templates/create',
  ACCOUNT_MESSAGETEMPLATES_ID: (id) => `/account/message-templates/${id}`,

  // ACCOUNT_JOBBOARD: '/account/jobboard',
  ACCOUNT_JOB_CATEGORIES: '/account/categories',
  ACCOUNT_ARCHIVE_REASONS: '/account/archive-reasons',
  ACCOUNT_AI_AGENTS: '/account/ai-agents',
  ACCOUNT_AI_AGENTS_CREATE: '/account/ai-agents/create',
  ACCOUNT_AI_AGENT: (agentId) => `/account/ai-agents/${agentId}`,
  ORGANIZATION_INTEGRATIONS: '/account/integrations',
  ORGANIZATION_INTEGRATIONS_CAL: '/account/integrations/com.cal',
  ORGANIZATION_INTEGRATIONS_SLACK: '/account/integrations/com.slack',
  ORGANIZATION_DUMMY_DATA: '/account/dummy-data',
  ACCOUNT_PAGES: '/account/pages',
  ACCOUNT_PAGE_CREATE: '/account/pages/create',
  ACCOUNT_PAGE: (pageId) => `/account/pages/${pageId}`,
  ACCOUNT_FORMS: '/account/forms',
  ACCOUNT_FORM_CREATE: `/account/forms/create`,
  ACCOUNT_FORM: (formId) => `/account/forms/${formId}`,
  ORGANIZATION_PLAN_BILLING: '/account/plan-billing',
  EMAIL_FORWARDING: '/account/email-forwarding',

  ACCOUNT_JOBBOARD_BRANDING: '/account/jobboard/branding',
  ACCOUNT_JOBBOARD_CONTENT: '/account/jobboard/content',
  ACCOUNT_JOBBOARD_SEO: '/account/jobboard/seo',
  ACCOUNT_JOBBOARD_NAVIGATION: '/account/jobboard/navigation',
  ACCOUNT_JOBBOARD_CUSTOMDOMAIN: '/account/jobboard/custom-domain',

  ORGANIZATION: '/organization',
  ORGANIZATION_MANAGE: '/organization/manage',
  ORGANIZATION_NEW: '/organization/new',

  DOMAIN_CREATE: '/domain/create',

  NOTIFICATION: '/notification',

  REVIEWS: `/reviews`,

  ALL_APPLICANTS: '/all-applicants',
  ALL_APPLICANT_ID: (applicantId) => `/all-applicants/${applicantId}`,

  ALL_INTERVIEWS: '/all-interviews',
  ALL_INTERVIEWS_ID: (applicantId) => `/all-interviews/${applicantId}`,

  ALL_MESSAGES: '/all-messages',
  ALL_MESSAGE_ID: (messageId) => `/all-messages/${messageId}`,

  ALL_CALLS: '/all-calls',
  ALL_RECORDING_ID: (recordingId) => `/all-recordings/${recordingId}`,

  COMMUNICATIONS: '/communications',
  COMMUNICATIONS_GROUP: (messageGroup) => `/communications/${messageGroup}`,
  COMMUNICATIONS_ID: (messageGroup, messageId) =>
    `/communications/${messageGroup}/${messageId}`,
};

export const LOGOUT_REQUIRED_ROUTES = [
  routes.SIGNUP_WITH_INVITE,
  routes.VERIFY_EMAIL,
];
